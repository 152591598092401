import { Navigate, Route, Routes } from 'react-router-dom';
import { routeConfig } from '../../config/route.config';
import { AboutUs } from './about-us/AboutUs';
import { EnquiryForm } from './enquiry-form/EnquiryForm';
import { Home } from './home/Home';
import { Hours } from './hours/Hours';
import { MiniGolf } from './mini-golf/MiniGolf';
import { Photos } from './photos/Photos';
import { PitchAndPutt } from './pitch-and-putt/PitchAndPutt';

export const Pages = () => {
  return (
    <Routes>
      <Route path={routeConfig.root.path} element={<Home />} />
      <Route path={routeConfig.aboutUs.path} element={<AboutUs />} />
      <Route path={routeConfig.miniGolf.path} element={<MiniGolf />} />
      <Route path={routeConfig.pitchAndPutt.path} element={<PitchAndPutt />} />
      {/* <Route path={routeConfig.cafeMenu.path} element={<CafeMenu />} /> */}
      {/* <Route path={routeConfig.golfPrice.path} element={<GolfPrice />} /> */}
      <Route path={routeConfig.hours.path} element={<Hours />} />
      <Route path={routeConfig.photos.path} element={<Photos />} />
      <Route path={routeConfig.enquiryForm.path} element={<EnquiryForm />} />
      <Route path='*' element={<Navigate replace to={routeConfig.root.path} />} />
    </Routes>
  );
};
