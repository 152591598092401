import { Box, Center, Hide, Show, SimpleGrid, Text } from '@chakra-ui/react';
import { Link, Location, useLocation } from 'react-router-dom';
import { routeConfig } from '../../config/route.config';
import { Route } from '../../config/route.config.types';

const routes: Route[] = [
  routeConfig.aboutUs,
  routeConfig.miniGolf,
  routeConfig.pitchAndPutt,
  // routeConfig.cafeMenu,
  // routeConfig.golfPrice,
  routeConfig.hours,
  routeConfig.photos,
  routeConfig.enquiryForm,
];

// const getIconByPath = (pathname: string): IconType | undefined => {
//   switch (pathname) {
//     case routeConfig.aboutUs.path:
//       return MdEmojiPeople;
//     case routeConfig.miniGolf.path:
//       return MdSportsGolf;
//     case routeConfig.pitchAndPutt.path:
//       return MdGolfCourse;
//     case routeConfig.cafeMenu.path:
//       return FiCoffee;
//     case routeConfig.golfPrice.path:
//       return MdOutlinePriceChange;
//     case routeConfig.hours.path:
//       return MdOutlineAccessTime;
//     case routeConfig.photos.path:
//       return CgInstagram;
//     case routeConfig.enquiryForm.path:
//       return AiOutlineMail;
//     default:
//       return undefined;
//   }
// };

export const Navigator = () => {
  const location: Location = useLocation();

  return (
    <SimpleGrid
      columns={{ base: 2, md: 8, lg: 1 }}
      padding={{ base: 2, lg: '2rem 1rem 1rem' }}
      fontFamily='OctinCollegeRegular'>
      {routes.map((route: Route) => {
        const borderColor: string = location.pathname === route.path ? '#ff9a33' : 'transparent';
        return (
          <Box
            key={route.name}
            w='100%'
            padding={{ base: 2, lg: '0 1rem' }}
            border='4px solid'
            borderColor={borderColor}
            borderRadius={8}>
            <Link to={route.path}>
              <Show above='lg'>
                <Text fontSize='5xl' _hover={{ color: '#0495ea' }}>
                  {route.name.toUpperCase()}
                </Text>
              </Show>
              <Hide above='lg'>
                <Center>
                  {/* <Icon as={getIconByPath(route.path)} boxSize={{ base: 8, sm: 10, md: 14 }} /> */}
                  {route.name.toUpperCase()}
                </Center>
              </Hide>
            </Link>
          </Box>
        );
      })}
    </SimpleGrid>
  );
};
